<template>
  <v-container class="mt-12"><AuthSignIn /></v-container>
</template>
<script>
import AuthSignIn from "../components/AuthSignIn.vue";

export default {
  components: {
    AuthSignIn,
  },
};
</script>
